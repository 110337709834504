import React from 'react'

import MainLayout from '../../layouts/mainlayout/MainLayout'
import PageHeader from '../../components/pageheader/PageHeader'

function LifeAg() {
	return (
		<MainLayout title="About Us | Life @ AG">
			<PageHeader title="Life @ AG" subnav="about" />
			Life @ AG
		</MainLayout>
	)
}

export default LifeAg